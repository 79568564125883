.featured{
    font-size: 40px;
    margin-top: 50px;
    padding-left: 20px;
}

.service-button{
    background-color:#e31b6d ;
    font-size: 28px;
    color: #000;
}