.service{
    background-color:#fff;
    display: flex;
    flex-direction: column;
}
.service-icon{
    font-size: 100px;
    background-color: #e31b6d;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.service-inner h1{
    font-weight: 700;

}
.service-inner h4{
    font-size: 18px;
    
}
.service-inner>p{
    font-size: 16px;
    
}
.project-button-test{
    color: #fff!important;
    font-weight: 700;
}