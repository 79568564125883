.banner{
    /* background-image :url(/images/banner-background.jpg) ; */
    /* https://ak.picdn.net/shutterstock/videos/1016116837/thumb/1.jpg */

    /* background-image: url(/images/banner1.jpg); */

    /* background-image: url(https://ak.picdn.net/shutterstock/videos/1016116837/thumb/1.jpg); */
    /* background-image: url(https://img.freepik.com/free-photo/old-black-background_1258-15670.jpg?size=626&ext=jpg);
    background-repeat: no-repeat;
    background-size: cover; */
    background-color:#000000;
    height:100%;
    width:100%;
    align-items: center;
    min-height: 65vh;
    color: #fff;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
}

.banner-main-txt{
    font-weight: 700;
}
.banner-text-name{
    color:#e31b6d;
    font-weight: 700;
}

.banner-button{
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 28px;
    color: #ffffff;
}

.banner-button {
    background-color:#e31b6d;
}


.featured{
    font-size: 40px;
    margin-top: 80px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 800;
}
.featured-test{
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 800;
    color: #fff;
}
.head-border-test{
    width: 70px;
    height: 6px;
    background-color:#fff;
    margin-bottom: 40px;
}
.banner-subtext{
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 10px;
}
.head-border{
    width: 70px;
    height: 6px;
    background-color:#e31b6d;
    margin-bottom: 100px;
}
.project-title{
    font-weight: 800;
}
.project-text p{
    font-size: 22px;
}
.project-features{
    padding: 0;
}
.project-features li{
    padding: 0 0 10px 0;
    font-size: 22px;

}
.pot-img{
    /* clip-path: polygon(75% 0%, 95% 50%, 75% 100%, 6% 100%, 25% 50%, 6% 0%); */
}