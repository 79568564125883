.about{
    margin: 0;
    padding: 0;
}

.don-img{
    max-height: 200px;
    overflow: hidden;
    margin-bottom: 25px;
}

   