/* .project-section{
    padding: 0px;
    margin: 0px;
} */

.project{
    padding: 0;
    border: 0px;
    margin: 2em 4em;
}

.project-title{
    font-size: 50px;
}

.project-text{
    font-size: 30px;
}

.project-text ul{
    list-style: none;
}

.project-button{
    background-color:#e31b6d ;
    font-size: 28px;
    color: #000;
}

.project-button:hover {
    background-color:rgba(227,27,109,0.8);
}
.banner-image img {
    box-shadow: -20px -20px 0 rgba(0,0,0,0.1);
}