.icon-bg{
    display: inline-block;
    position: relative;
    border-radius: 6px;
    text-align: center;
    /* background: #f6f6f6; */
}
.icon-bg p {
    font-size: 64px;
    /* padding: 20px 0 0;
    width: 155px; */
    text-align: center;
    /* height: 132px; */
    color:#e31b6d;
}

