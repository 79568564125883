.main-nav{
    font-weight: 800 !important;
    background-color: #000000!important;
    margin: 0!important;
    height: 15vh;

    /* background-image: linear-gradient(to bottom, rgba(0,0,0,0.1) ,rgba(0,0,0,0));
    position: fixed;
    width: 100%;
    z-index: 1000000000000; */
}

.nav-links{
    padding: 20px;
}

.nav-links a{
    color: rgba(255,255,255,0.8)!important;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.nav-links a:hover{
    color:#e31b6d !important;
}

.nav-links a:active{
    color:#e31b6d !important;
}
.logo{
    width: 80px;
}