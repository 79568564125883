.service {
    background-color: #fff;
}
.service-pg-icon svg {
    font-size: 150px;
    color: #e31b6d;
}
.service-pg-icon {
    text-align: center;
}
.service-pg-wrap{
    background: #f6f6f6;
    margin-top: 5rem;
    border-top: 5px solid #e31b6d;
    border-radius: 5px;
}
.serviceName{
    font-size: 30px;
    font-weight: 700;
}
.heading{
    font-weight: 500;
    font-size: 18px;
    margin-top: 15px;
}
.description{
    font-weight: 400;
    font-size: 13px;
}
.service-pg-conten{
    padding-right: 15px;
}