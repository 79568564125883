.contact-background{
    align-items: center;
    background-color: #f6f6f6;
    margin: 5em;
    padding: 3em;
   

}

.availability{
    color: #e31b6d;
}

.form{
    text-align: left;
    margin: 5em;
}

.form-button{
    text-align: center;
    margin: 0 auto;
}
.contact-btn{
    margin: 0 auto;
}
.form-inners{
    font-size: 14px;
    color: #555;
    font-weight: 500;
}
.form-inners input, .form-inners textarea{
    border: 1px solid #ddd;
}