
footer{
    padding: 200px 0 100px; 
    background-color: #000;
    color: #fff;
    -webkit-clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 100%);
}
.copyright{
    padding-top: 60px;
}
.project-button{
    background-color:#e31b6d ;
    font-size: 28px;
    color: #fff;
}

.footer-list ul{
    list-style: none;
}
.footer-list ul li{
  padding: .5rem 1rem;
  font-weight: 600;
}


.footer-list a {
    color: #FFF;
}

.footer-list a:hover  {
    text-decoration: none;
}

