@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-nav{
    font-weight: 800 !important;
    background-color: #000000!important;
    margin: 0!important;
    height: 15vh;

    /* background-image: linear-gradient(to bottom, rgba(0,0,0,0.1) ,rgba(0,0,0,0));
    position: fixed;
    width: 100%;
    z-index: 1000000000000; */
}

.nav-links{
    padding: 20px;
}

.nav-links a{
    color: rgba(255,255,255,0.8)!important;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.nav-links a:hover{
    color:#e31b6d !important;
}

.nav-links a:active{
    color:#e31b6d !important;
}
.logo{
    width: 80px;
}
.service{
    background-color:#fff;
    display: flex;
    flex-direction: column;
}
.service-icon{
    font-size: 100px;
    background-color: #e31b6d;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.service-inner h1{
    font-weight: 700;

}
.service-inner h4{
    font-size: 18px;
    
}
.service-inner>p{
    font-size: 16px;
    
}
.project-button-test{
    color: #fff!important;
    font-weight: 700;
}
.featured{
    font-size: 40px;
    margin-top: 50px;
    padding-left: 20px;
}

.service-button{
    background-color:#e31b6d ;
    font-size: 28px;
    color: #000;
}
.testimonial{
    background-image: url("https://gridwise.io/wp-content/uploads/2017/01/testimonials-background-img-revised.jpg");
    -webkit-filter: opacity(100%);
            filter: opacity(100%);
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 3em;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 25px 0;
}

/* .quote:before, .quote:after {
    transform: translateX(-50%);
    width: 60px;
    height: 6px;
    position: absolute;
    left: 50%;
    content: "";
    border-radius: 1000px;
    background: #000;
} */
.testimonial-text{
    font-size: 18px;
    font-weight: 300;
    color: #eee;

}
.testimonial h4{
    color: #eee;
}
.occupation{
    color: #eee;
}

.banner{
    /* background-image :url(/images/banner-background.jpg) ; */
    /* https://ak.picdn.net/shutterstock/videos/1016116837/thumb/1.jpg */

    /* background-image: url(/images/banner1.jpg); */

    /* background-image: url(https://ak.picdn.net/shutterstock/videos/1016116837/thumb/1.jpg); */
    /* background-image: url(https://img.freepik.com/free-photo/old-black-background_1258-15670.jpg?size=626&ext=jpg);
    background-repeat: no-repeat;
    background-size: cover; */
    background-color:#000000;
    height:100%;
    width:100%;
    align-items: center;
    min-height: 65vh;
    color: #fff;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
}

.banner-main-txt{
    font-weight: 700;
}
.banner-text-name{
    color:#e31b6d;
    font-weight: 700;
}

.banner-button{
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 28px;
    color: #ffffff;
}

.banner-button {
    background-color:#e31b6d;
}


.featured{
    font-size: 40px;
    margin-top: 80px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 800;
}
.featured-test{
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 800;
    color: #fff;
}
.head-border-test{
    width: 70px;
    height: 6px;
    background-color:#fff;
    margin-bottom: 40px;
}
.banner-subtext{
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 10px;
}
.head-border{
    width: 70px;
    height: 6px;
    background-color:#e31b6d;
    margin-bottom: 100px;
}
.project-title{
    font-weight: 800;
}
.project-text p{
    font-size: 22px;
}
.project-features{
    padding: 0;
}
.project-features li{
    padding: 0 0 10px 0;
    font-size: 22px;

}
.pot-img{
    /* clip-path: polygon(75% 0%, 95% 50%, 75% 100%, 6% 100%, 25% 50%, 6% 0%); */
}
.page-heading{
    font-size: 50px!important;
    font-weight: 500!important;
}

/* .project-section{
    padding: 0px;
    margin: 0px;
} */

.project{
    padding: 0;
    border: 0px;
    margin: 2em 4em;
}

.project-title{
    font-size: 50px;
}

.project-text{
    font-size: 30px;
}

.project-text ul{
    list-style: none;
}

.project-button{
    background-color:#e31b6d ;
    font-size: 28px;
    color: #000;
}

.project-button:hover {
    background-color:rgba(227,27,109,0.8);
}
.banner-image img {
    box-shadow: -20px -20px 0 rgba(0,0,0,0.1);
}
.icon-bg{
    display: inline-block;
    position: relative;
    border-radius: 6px;
    text-align: center;
    /* background: #f6f6f6; */
}
.icon-bg p {
    font-size: 64px;
    /* padding: 20px 0 0;
    width: 155px; */
    text-align: center;
    /* height: 132px; */
    color:#e31b6d;
}


.service {
    background-color: #fff;
}
.service-pg-icon svg {
    font-size: 150px;
    color: #e31b6d;
}
.service-pg-icon {
    text-align: center;
}
.service-pg-wrap{
    background: #f6f6f6;
    margin-top: 5rem;
    border-top: 5px solid #e31b6d;
    border-radius: 5px;
}
.serviceName{
    font-size: 30px;
    font-weight: 700;
}
.heading{
    font-weight: 500;
    font-size: 18px;
    margin-top: 15px;
}
.description{
    font-weight: 400;
    font-size: 13px;
}
.service-pg-conten{
    padding-right: 15px;
}
.contact-background{
    align-items: center;
    background-color: #f6f6f6;
    margin: 5em;
    padding: 3em;
   

}

.availability{
    color: #e31b6d;
}

.form{
    text-align: left;
    margin: 5em;
}

.form-button{
    text-align: center;
    margin: 0 auto;
}
.contact-btn{
    margin: 0 auto;
}
.form-inners{
    font-size: 14px;
    color: #555;
    font-weight: 500;
}
.form-inners input, .form-inners textarea{
    border: 1px solid #ddd;
}
.about{
    margin: 0;
    padding: 0;
}

.don-img{
    max-height: 200px;
    overflow: hidden;
    margin-bottom: 25px;
}

   
.about-page{
    margin-top: 7em;
    padding: 5em;
    line-height: 40px;
}


.text{
    align-items: center;
    font-size: 40px;
    font-weight: 700;
    color: #000000;
}

.intro-text{
    font-size: 18px;
    font-weight: 500;
    color: #444;
}
.about-text{
    font-size: 15px;
    line-height: 25px;
}

footer{
    padding: 200px 0 100px; 
    background-color: #000;
    color: #fff;
    -webkit-clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 100%);
}
.copyright{
    padding-top: 60px;
}
.project-button{
    background-color:#e31b6d ;
    font-size: 28px;
    color: #fff;
}

.footer-list ul{
    list-style: none;
}
.footer-list ul li{
  padding: .5rem 1rem;
  font-weight: 600;
}


.footer-list a {
    color: #FFF;
}

.footer-list a:hover  {
    text-decoration: none;
}


body{
  font-family: 'Roboto Slab', serif;
  color: #000000;
}

body{
  font-family: 'Montserrat', sans-serif;
}
