.testimonial{
    background-image: url("https://gridwise.io/wp-content/uploads/2017/01/testimonials-background-img-revised.jpg");
    filter: opacity(100%);
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 3em;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 25px 0;
}

/* .quote:before, .quote:after {
    transform: translateX(-50%);
    width: 60px;
    height: 6px;
    position: absolute;
    left: 50%;
    content: "";
    border-radius: 1000px;
    background: #000;
} */
.testimonial-text{
    font-size: 18px;
    font-weight: 300;
    color: #eee;

}
.testimonial h4{
    color: #eee;
}
.occupation{
    color: #eee;
}
