@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;800&display=swap');

body{
  font-family: 'Roboto Slab', serif;
  color: #000000;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800;900&display=swap');

body{
  font-family: 'Montserrat', sans-serif;
}