.about-page{
    margin-top: 7em;
    padding: 5em;
    line-height: 40px;
}


.text{
    align-items: center;
    font-size: 40px;
    font-weight: 700;
    color: #000000;
}

.intro-text{
    font-size: 18px;
    font-weight: 500;
    color: #444;
}
.about-text{
    font-size: 15px;
    line-height: 25px;
}